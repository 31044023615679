@import '../../styles/propertySets.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  margin: 0 24px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.author {
  display: flex;
  flex-direction: row;
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin: 24px 24px 32px 24px;

  @media (--viewportMedium) {
    margin: 24px 0 32px 0;
    color: var(--matterColor);
  }
}
.authorValidation {
  display: flex;
  flex-direction: row;
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin: 24px 24px 0px 24px;

  @media (--viewportMedium) {
    margin: 24px 0 0px 0;
    color: var(--matterColor);
  }
}

.providerAvatar {
  margin-right: 10px;
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 0px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.orderButton {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}
.container {
  display: none;
  @media (--viewportLarge) {
    display: flex;
    margin: 10px 0px;
  }
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
}
.right {
}
.icon {
  height: 30px;
  width: 30px;
}
.heading {
  margin: 0;
  font-weight: 600;
}
.text {
  margin: 0;
  font-size: 0.8rem;
  line-height: 20px;
  color: gray;
}
.fee {
  display: none;
  @media (--viewportLarge) {
    display: block;
    margin: 0;
    font-size: 0.8rem;
    line-height: 20px;
    color: gray;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    padding: 0.25rem 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0.5rem;
  line-height: 1.4;
  font-size: 0.975rem;
}

.activeLikeListing {
  cursor: pointer;
  & .likeListingIcon {
    & path {
      fill: #cf4444;
      stroke: #cf4444;
    }
  }
}
.likeListing {
  cursor: pointer;
  margin-top: 4px;
  height: 30px;
  width: 68px;
  margin-right: 1rem;
  & path {
    /* fill: var(--matterColorAnti); */
    stroke: var(--matterColorAnti);
  }
  &:hover {
    & .likeListingIcon {
      & path {
        fill: #e5b3b3;
        stroke: #e5b3b3;
      }
    }
  }
}
