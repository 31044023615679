.button {
  width: 35px;
  /* height: 35px; */
  border-radius: 50%;
  border: none;
}
.icon {
  font-size: 20px;
  color: var(--marketplaceColor);
  cursor: pointer;
  margin-top: -0.5rem;
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
.body {
  padding: 0.5rem;
}
.popup-content {
  border-radius: 10px;
}
.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
