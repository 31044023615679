.root {
}

.input {
  border-bottom: 2px solid;
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom: 2px solid;
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom: 2px solid;
  border-bottom-color: var(--failColor);
}

.textarea {
}
.labelContainer {
  display: flex;
}
