.root {
  width: min(700px, 100%);
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.span2 {
  grid-column: span 2;
}
.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.cardInput {
  border: 1px solid hsl(0, 0%, 93%);
  width: min(100%, 500px);
  height: 2.5rem;
}

.cardLabel {
  margin: 1rem 0 0.25rem 0;
}

.submitButtonWrapper {
  margin-top: 2rem;
}

.error {
  margin: 0;
  margin: 0.35rem;
  color: red;
  font-weight: normal;
  line-height: 1.4;
  font-size: 0.9rem;
}

.errorList {
  padding-left: 1rem;
  list-style-type: disc;
}

.field {
  margin-bottom: 1rem;
}
