.root {
  width: min(700px, 100%);
  margin: 0 auto;
}

.grid {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: repeat(1, 1fr); */
  gap: 1rem;
  @media (--viewportMedium) {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
  }
}
.textNote{
  margin: 0;
  line-height: 1.4;
}
.span2 {
  grid-column: span 2;
}
.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}
.termsAndConditions {
  margin-top: 1rem;
}
.paragraph {
  margin: 0;
  margin-top: -4px;
  font-size: 15px;
}
/* .termsOfservice {
  display: flex;
} */
.link {
  margin-left: 0.5rem;
}
.walletSuccess {
  /* border: 1px solid var(--successColor); */
  padding: 0.1rem;
  padding-left: 0.5rem;
  background-color: var(--marketplaceColorLight);
  color: white;
  border-radius: 0.2rem;
  border-left: 5px solid var(--marketplaceColorDark);
}
.walletSucessText {
  font-size: 18px;
  font-weight: 500;
}
.information {
  border: 2px dashed var(--matterColorAnti);
  margin-top: 1rem;
  padding: 1rem;
}
.heading {
  color: var(--matterColor);
}
.subHeading {
  color: var(--matterColor);
  margin: 0;
}
.rejectHeading {
  color: var(--matterColor);
  margin: 0;
}
.mainContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.list {
  margin: 0;
  font-size: 14px;
  line-height: 2;
}
.checkMark {
  color: var(--successColor);
  margin-right: 0.5rem;
}
.crossMark {
  color: var(--failColor);
  margin-right: 0.5rem;
}
.link {
  margin-left: 0.5rem;
}
.note {
  color: var(--failColor);
}
.identityHeading {
  color: var(--matterColor);
  margin: 0;
  font-size: 16px;
}
.subHeading {
  color: var(--matterColor);
  margin: 0;
  font-size: 14px;
}
.text {
  color: var(--matterColor);
  margin: 0;
  line-height: 1.3;
  font-size: 14px;
  margin-bottom: 0.25rem;
}
.kycFailed{
}