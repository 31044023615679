.subHeading {
  color: hsl(0, 0%, 57%);
  margin: 0;
  padding: 0;
}
.label {
  margin-bottom: 1rem;
}
.left {
  display: flex;
}
.icon {
  /* margin-top: 20px; */
  margin-top: 2px !important;
  @media (--viewportMedium) {
    margin-top: 5px !important;
  }
  margin-bottom: 0px !important;
  height: 20px;
  width: 20px;
  /* border: 1px solid lightgray; */
  /* border-radius: 30px; */
  margin-right: 0.5rem;
}
.customLabel {
  margin: 0 !important;
}
