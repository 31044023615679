@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceColor);
  @media (--viewportMedium) {
    margin-top: 0;
    margin-top: 0.15rem;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  line-height: 24px;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.toolTipContainer {
  padding: 1rem;
}
.toolTipHeading {
  /* margin: 0; */
  text-align: center;
}
.headingText {
  margin: 0;
  margin-bottom: 0.5rem;
  /* font-weight: 600;  */
  line-height: 1.8;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon {
  font-size: 80px;
  color: var(--marketplaceColor);
}
.priceImage {
  height: 120px;
  width: 120px;
}
.activeLikeListing {
  cursor: pointer;
  & .likeListingIcon {
    & path {
      fill: #cf4444;
      stroke: #cf4444;
    }
  }
}
.likeListing {
  cursor: pointer;
  margin-top: -9px;
  height: 28px;
  width: 38px;
  & path {
    /* fill: var(--matterColorAnti); */
    stroke: var(--matterColorAnti);
  }
  &:hover {
    & .likeListingIcon {
      & path {
        fill: #e5b3b3;
        stroke: #e5b3b3;
      }
    }
  }
}
.container {
  display: flex;
  justify-content: space-between;
}
.wishlist {
  display: flex;
}
.count {
  color: var(--matterColorAnti);
  margin-left: -5px;
}
.removeFromWishlist {
  cursor: pointer;
  font-size: 15px;
  color: var(--matterColorAnti);
  text-decoration: underline;
  &:hover {
    color: var(--matterColor);
  }
}
.outOfStock {
  position: absolute;
  background-color: #7bb524;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1rem;
  font-weight: 600;
  color: white;
}
