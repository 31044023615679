.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
.container {
  display: flex;
}
.rangepicker {
  width: 100%;
  margin-right: 10px;
}
.textcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
