.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.imageOne {
  /* height: 150px; */
  width: 250px;
}
.imageTwo {
  /* height: 100px; */
  width: 120px;
}
.mainImage {
  width: 100%;
  height: 100%;
}
.instagramText {
  color: var(--marketplaceColor);
  text-align: center;
}
.link {
  text-align: center;
}
.heading {
  text-align: center;
}
.subHeading {
  text-align: center;
}
.icon {
  height: 30px;
  width: 30px;
  color: var(--matterColor);
  margin-left: 45%;
}
