.root {
  --upload-button-width: 300px;
  --upload-button-height: 300px;
  --upload-button-normal-bg: hsl(0, 0%, 97%);
  --upload-button-hover-bg: hsl(0, 0%, 93%);
  --upload-button-normal-outline: hsl(0, 0%, 73%);
  --upload-button-hover-outline: hsl(0, 0%, 63%);
  --preview-normal-outline: hsl(0, 0%, 73%);
  --preview-hover-outline: hsl(0, 0%, 63%);
}

.uploadButton {
  height: var(--upload-button-height);
  background-color: var(--upload-button-normal-bg);
  width: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 2px dashed var(--upload-button-normal-outline);
  margin: 2px;
  transition: all 150ms linear;
  flex-direction: column;
  color: #111;
  padding: 2rem;
  @media (--viewportMedium) {
    width: var(--upload-button-width);
  }
  &:hover {
    background-color: var(--upload-button-hover-bg);
    outline: 2px dashed var(--upload-button-hover-outline);
    cursor: pointer;
  }
}
.disabledUploadButton {
  pointer-events: none;
  color: #888;
}

.disabledClassName,
.disabledPreviewLabelClassName {
  cursor: not-allowed !important;
}

.previewClassName {
  width: var(--upload-button-width);
  height: var(--upload-button-height);
  object-fit: cover;
}

.previewLabelClassName {
  height: var(--upload-button-height);
  width: var(--upload-button-width);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms linear;
  outline: 2px dashed var(--preview-normal-outline);
  margin: 2px;

  &:hover {
    outline: 2px dashed var(--preview-hover-outline);
    cursor: pointer;
    opacity: 0.8;
  }
}

.loading {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  height: var(--upload-button-height);
  width: var(--upload-button-width);
}

.spinner {
  position: absolute;
}
.subLabel {
  color: hsl(0, 0%, 70%);
}
