*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.m-0 {
  margin: 0px
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem
}

.mt-0 {
  margin-top: 0px
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mt-4 {
  margin-top: 1rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mt-32 {
  margin-top: 8rem
}

.mt-8 {
  margin-top: 2rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mb-4 {
  margin-bottom: 1rem
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.w-full {
  width: 100%
}

.grow {
  flex-grow: 1
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.resize {
  resize: both
}

.list-disc {
  list-style-type: disc
}

.flex-col {
  flex-direction: column
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.gap-4 {
  gap: 1rem
}

.gap-2 {
  gap: 0.5rem
}

.self-end {
  align-self: flex-end
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.break-all {
  word-break: break-all
}

.rounded-full {
  border-radius: 9999px
}

.rounded {
  border-radius: 0.25rem
}

.rounded-md {
  border-radius: 0.375rem
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-marketplaceColorDark {
  --tw-border-opacity: 1;
  border-color: rgb(123 37 39 / var(--tw-border-opacity))
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity))
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity))
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity))
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity))
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.bg-marketplaceColorDark {
  --tw-bg-opacity: 1;
  background-color: rgb(123 37 39 / var(--tw-bg-opacity))
}

.bg-marketplaceColor\/90 {
  background-color: rgb(185 87 90 / 0.9)
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-marketplaceColor {
  --tw-bg-opacity: 1;
  background-color: rgb(185 87 90 / var(--tw-bg-opacity))
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity))
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}

.bg-marketplaceColor\/80 {
  background-color: rgb(185 87 90 / 0.8)
}

.p-4 {
  padding: 1rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.pl-4 {
  padding-left: 1rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.italic {
  font-style: italic
}

.leading-snug {
  line-height: 1.375
}

.tracking-wide {
  letter-spacing: 0.025em
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-marketplaceColor\/80 {
  color: rgb(185 87 90 / 0.8)
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity))
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity))
}

.text-marketplaceColorDark {
  --tw-text-opacity: 1;
  color: rgb(123 37 39 / var(--tw-text-opacity))
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline {
  outline-style: solid
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-150 {
  transition-duration: 150ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.ease-linear {
  transition-timing-function: linear
}

.hover\:cursor-pointer:hover {
  cursor: pointer
}

.hover\:bg-marketplaceColorDark\/75:hover {
  background-color: rgb(123 37 39 / 0.75)
}

.hover\:bg-marketplaceColorDark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(123 37 39 / var(--tw-bg-opacity))
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity))
}

@media (min-width: 640px) {
  .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px
  }

  .sm\:mt-0 {
    margin-top: 0px
  }

  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }
}

@media (min-width: 768px) {
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px
  }

  .md\:mt-0 {
    margin-top: 0px
  }

  .md\:w-full {
    width: 100%
  }

  .md\:max-w-2xl {
    max-width: 42rem
  }

  .md\:rounded-md {
    border-radius: 0.375rem
  }

  .md\:shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }
}