@import '../../styles/customMediaQueries.css';
.desktop {
  width: 200px;
  margin-top: -10px;
  object-fit: contain;
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}
.mobile {
  width: 43px;
  height: 43px;
  margin-top: -10px;
  display: block;
  @media (--viewportLarge) {
    display: none;
  }
}
